import React from "react";
import dynamic from "next/dynamic";
import { NextSeoProps } from "next-seo";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import classNames from "classnames";
import Product from "@Models/product.model";
import { _metadata } from "@Utils/local-data";
import HomePage from "@Models/homepage.model";
import Keyword, { EType, TListRoom } from "@Models/keyword.model";
import Brand, { IBrand } from "@Models/brand.model";
import CuaHangNoiThat from "@Modules/homev2/components/CuaHangNoiThat";
import ContentEndPage from "@Modules/homev2/components/end-of-page";
import { FlashSale } from "@Models/flashSale.model";
import { GlobalComponentProps } from "@Models/types";
import { sharedServerSideLogic } from "@Utils/sharedServerSideLogic";
import DialogAds from "@Components/DialogAds";
import { getDevices, getUserSecretIp } from "@Utils/SSR/index.handler";
import Banner, { EBannerDevice, EBannerSection, IBanner } from "@Models/banner.model";
import { logclient } from "@Utils/log";
import MainCategory from "@Modules/homev2/components/MainCategory";
import HornorBrand from "@Modules/homev2/components/HornorBrand";
import SpacetParner from "@Modules/homev2/components/SpacetParner";
import ModalReceiveCoin from "@Components/modal/ModalReceiveCoin";
import { getMainCategorys } from "@Config/shop";
import NewsBrand2 from "@Modules/homev2/components/NewsBrand2";
import BenefitWithSpaceT from "@Modules/homev2/components/BenefitWithSpaceT";
import CalculateCosts from "@Modules/homev2/components/CalculateCosts";
import KhachHangReview from "@Modules/homev2/components/KhachHangReview";
import DuAnHoanThienWrap from "@Modules/homev2/components/du-an-hoan-thien/DuAnHoanThienWrap";
import HinhAnhNoiThatWrap from "@Modules/homev2/components/hinh-anh-noi-that/HinhAnhNoiThatWrap";
import GeneralMediasBySpace, {
   EGeneralMediasBySpaceSortBy,
   IGeneralMediasBySpace,
} from "@Models/generalMediasBySpace.model";
import { ModelError, Paginate } from "@Models/model";
import ActualWork, { EActualWorkTypes, IActualWork } from "@Models/actualWork.model";
import BaiVietNoiBat from "@Modules/homev2/components/BaiVietNoiBat";
import Community, { ICommunity } from "@Models/community.model";
import { menuCategories } from "./constants";

const Banners = dynamic(() => import("@Modules/homev2/components/Banners"), {
   ssr: true,
   loading: () => <div style={{ height: "400px" }} />,
});

export const URL_ACCEPT_BUTTON = ["/", "/thiet-ke-noi-that", "/thi-cong-noi-that", "/quy-trinh-ket-noi"];
interface IState {
   dialogReceiveCoin?: boolean;
}

interface IProps extends GlobalComponentProps, InferGetServerSidePropsType<typeof getServerSideProps> {
   auth: any;
}

class Home extends React.Component<IProps, IState> {
   constructor(props: IProps) {
      super(props);
      this.state = { dialogReceiveCoin: false };
   }

   componentDidMount(): void {
      window.history.scrollRestoration = "auto";
   }

   handleCloseDialog = () => {
      this.setState({ dialogReceiveCoin: false });
   };

   renderBanners() {
      const { banners } = this.props;
      if (!banners || !banners?.length) return null;

      const homeBanners = banners.filter((banner) => banner.section === EBannerSection.homepage);
      const homeRightBanners = banners.filter((banner) => banner.section === EBannerSection.homepageright);

      return (
         <div className="block_banner">
            <div
               className={classNames("st-container", {
                  "pb-4 px-3": !isMobile,
                  "px-0 py-0": isMobile,
               })}
            >
               <Banners data={homeBanners} bannerRight={homeRightBanners} />
               <MainCategory menus={menuCategories} />
               <HornorBrand />
               <NewsBrand2 />
            </div>
         </div>
      );
   }

   renderMainContent() {
      const {
         categoriesHinhAnhNoiThatSSRData,
         dataOfSSRHinhAnhNoiThatBySlug,
         dataOfSSRHinhAnhNoiThat,
         dataOfSSRCanHoChungCuBySlug,
         dataOfSSRFlashSale,
         dataOfSSRSanPhamBanChay,
         dataOfSSRCommunityOverall,
         dataOfSSRToAmCuaToi,
         isMobile,
         // productsPropose,
         brands,
         menu,
         totalDuAnThucTe,
         totalCongTrinhThucTe,
         totalThuVienThietKe,
         totalToAmCuaToi,
      } = this.props;
      const shopCategories = getMainCategorys(menu.levelTwo.shop);

      return (
         <div className="main-content">
            <div className="st-container">
               <div className="row g-2 g-md-3">
                  {/*** Hinh anh noi that */}
                  <HinhAnhNoiThatWrap
                     categories={categoriesHinhAnhNoiThatSSRData}
                     dataBySlug={dataOfSSRHinhAnhNoiThatBySlug}
                     datas={dataOfSSRHinhAnhNoiThat}
                  />
                  {/* finish Hinh anh noi that */}

                  {/*** Dự án hoàn thiện */}
                  <DuAnHoanThienWrap dataBySlug={dataOfSSRCanHoChungCuBySlug} total={totalDuAnThucTe} />
                  {/* finish Dự án hoàn thiện */}

                  {/*** Lợi ích khi kết nối với Space T  */}
                  <BenefitWithSpaceT />
                  {/* finish Lợi ích khi kết nối với Space T  */}

                  {/*** Tính chi phí  */}
                  <CalculateCosts />
                  {/* finish Tính chi phí  */}

                  {/*** Khách hàng nói gì về Space T */}
                  <KhachHangReview />
                  {/* finish Khách hàng nói gì về Space T */}

                  {/*** Cửa hàng nội thất */}
                  <CuaHangNoiThat
                     isMobile={isMobile}
                     shopCategories={shopCategories}
                     flashSaleWithProduct={dataOfSSRFlashSale}
                     productsBanChay={dataOfSSRSanPhamBanChay}
                     // productsPropose={productsPropose}
                  />
                  {/* finish Cửa hàng nội thất */}

                  {/*** Bài viết nổi bật */}
                  <BaiVietNoiBat datas={dataOfSSRCommunityOverall} />
                  {/* finish Bài viết nổi bật */}

                  {/*** Đối tác của Space T */}
                  <SpacetParner />
                  {/* finish Đối tác của Space T */}

                  {/*** ContentEndPage */}
                  <ContentEndPage
                     brands={brands}
                     menulv2={menu.levelTwo?.shop?.filter((it) => it.info.slug)}
                     dataOfSSRHinhAnhNoiThat={dataOfSSRHinhAnhNoiThat}
                     totalThuVienThietKe={totalThuVienThietKe}
                     totalCongTrinhThucTe={totalCongTrinhThucTe}
                     totalToAmCuaToi={totalToAmCuaToi}
                  />
                  {/* finish ContentEndPage */}
               </div>
            </div>
         </div>
      );
   }

   render() {
      logclient(this);
      const { dialogReceiveCoin } = this.state;

      return (
         <>
            <div className="page-content" id="pageContent">
               <main className="home-pagev2">
                  <h1 className="d-none">Thiết kế, Thi công, Mua sắm nội thất toàn diện</h1>
                  {this.renderBanners()}
                  {this.renderMainContent()}
               </main>
            </div>
            <DialogAds />
            {dialogReceiveCoin && <ModalReceiveCoin visible={dialogReceiveCoin} onClose={this.handleCloseDialog} />}
         </>
      );
   }
}

const mapStateToProps = (state: any) => ({
   auth: state.auth,
});

export default connect(mapStateToProps)(Home);

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
   // *** initial
   const cookiesToken = ctx.req.cookies.userToken || "";
   const userIp = getUserSecretIp(ctx.req);
   const { isMobile } = getDevices(ctx.req);
   const urlParams = new URLSearchParams((ctx as any).query);
   // * finish initial

   // *** seo
   const [commonProps] = await Promise.all([sharedServerSideLogic(ctx)]);
   const title = _metadata[ctx?.resolvedUrl]?.title ?? "";
   const description = _metadata[ctx?.resolvedUrl]?.description ?? "";
   const seo: NextSeoProps = {
      title: commonProps?.metaData?.title || title,
      description: commonProps?.metaData?.description || description,
      openGraph: {
         title: commonProps?.metaData?.title || title,
         description: commonProps?.metaData?.description || description,
         type: "website",
         images: [
            {
               url: commonProps?.metaData?.thumbnail || _metadata[ctx?.resolvedUrl?.split("?")[0]]?.image || "",
            },
         ],
      },
   };
   // * finish seo

   // *** fetch ssr data for banners
   const bannerSSRData = await Banner.getBanners({
      sections: [EBannerSection.homepage, EBannerSection.homepageright],
      devicetype: isMobile ? EBannerDevice.mobileweb : EBannerDevice.web,
   });
   // * finish fetch ssr data for banners

   // *** fetch ssr for hinh anh noi that
   const categoriesHinhAnhNoiThatPromise: ModelError | TListRoom = await Keyword.getKeywordsByType([EType.space]);
   const categoriesHinhAnhNoiThatSSRData: any = Object.assign({}, categoriesHinhAnhNoiThatPromise);

   const firstSlugHinhAnhNoiThat = categoriesHinhAnhNoiThatSSRData?.space?.[0]?.info?.slug;
   const dataOfSSRHinhAnhNoiThatBySlugPromise = firstSlugHinhAnhNoiThat
      ? await GeneralMediasBySpace.paginatev2({
           page: 1,
           limit: 4,
           url: firstSlugHinhAnhNoiThat,
           sort: "desc",
           sortBy: EGeneralMediasBySpaceSortBy.viewCount,
        })
      : Promise.resolve({ docs: [], paginate: {} });
   const dataOfSSRHinhAnhNoiThatBySlug: any = Object.assign({}, await dataOfSSRHinhAnhNoiThatBySlugPromise);
   if (dataOfSSRHinhAnhNoiThatBySlug instanceof ModelError) {
      throw new Error("Failed to fetch galleries");
   }
   dataOfSSRHinhAnhNoiThatBySlug.docs = dataOfSSRHinhAnhNoiThatBySlug?.docs?.map(({ $updatedAt_DDMMYYY, ...rest }) =>
      Object.assign({}, rest)
   );

   // get number total HinhAnhNoiThat
   const responseHinhAnhNoiThat: any | Paginate<GeneralMediasBySpace, IGeneralMediasBySpace> | ModelError =
      await GeneralMediasBySpace.paginatev2({
         page: 1,
         limit: 1,
      });
   delete responseHinhAnhNoiThat?.docs;
   const dataOfSSRHinhAnhNoiThat: any = Object.assign({}, responseHinhAnhNoiThat);
   // * finish fetch ssr for Hình ảnh nội thất

   // *** fetch ssr for Dự án hoàn thiện
   const resCanHoChungCuBySlug: any = await ActualWork.getPaginate({
      limit: 4,
      articleType: EActualWorkTypes.designLibrary,
      url: "can-ho-chung-cu",
   });

   const dataOfSSRCanHoChungCuBySlug: any = {
      ...resCanHoChungCuBySlug,
      paginate: Object.assign({}, resCanHoChungCuBySlug?.paginate),
   };

   dataOfSSRCanHoChungCuBySlug.paginate.docs = dataOfSSRCanHoChungCuBySlug?.paginate?.docs?.map((item: any) => {
      const { $updatedAt_DDMMYYY, __media, __partner, ...rest } = item;
      return {
         ...rest,
         __media: __media ? { ...__media, $updatedAt_DDMMYYY: null } : null,
         __partner: __partner ? { ...__partner, $updatedAt_DDMMYYY: null } : null,
      };
   });
   // *** finish fetch ssr for Dự án hoàn thiện

   // *** Get Total for THIẾT KẾ THI CÔNG
   // Thư viện ảnh
   const thuVienThietKeResponse: any | Paginate<ActualWork, IActualWork> | ModelError = await ActualWork.getPaginate({
      limit: 4,
      articleType: EActualWorkTypes.designLibrary,
   });
   const totalThuVienThietKe = thuVienThietKeResponse?.paginate?.totalDocs ?? 0;
   // const dataOfSSRThuVienThietKe = JSON.parse(JSON.stringify(thuVienThietKeResponse));

   // Công trình thực tế
   const congTrinhThucTeResponse: any | Paginate<ActualWork, IActualWork> | ModelError = await ActualWork.getPaginate({
      limit: 1,
      articleType: EActualWorkTypes.actualWork,
   });

   const totalCongTrinhThucTe = congTrinhThucTeResponse?.paginate?.totalDocs ?? 0;
   // const dataOfSSRCongTrinhThucTe = JSON.parse(JSON.stringify(congTrinhThucTeResponse));

   const toAmCuaToiResponse: any | Paginate<Community, ICommunity> | ModelError = await Community.paginate({
      page: Number(urlParams.get("page")) || 1,
      limit: 4,
      sort: urlParams.get("sort") === "asc" ? "asc" : "desc",
   });
   const totalToAmCuaToi = toAmCuaToiResponse?.paginate?.totalDocs ?? 0;
   // const dataOfSSRToAmCuaToi = JSON.parse(JSON.stringify(toAmCuaToiResponse));

   const totalDuAnThucTe = totalThuVienThietKe + totalCongTrinhThucTe + totalToAmCuaToi;
   // * finish fetch ssr for Dự án hoàn thiện

   // *** fetch ssr for Flash sale
   const flashSalePromise: any | Paginate<GeneralMediasBySpace, IGeneralMediasBySpace> | ModelError =
      await FlashSale.paginate({ cookiesToken, page: 1, limit: 6 });

   const dataOfSSRFlashSale = {
      ...flashSalePromise,
      paginate: { ...flashSalePromise?.paginate },
      flashSales: { ...flashSalePromise?.flashSales },
   };
   dataOfSSRFlashSale.paginate.docs = dataOfSSRFlashSale?.paginate?.docs?.map(
      ({ $updatedAt_DDMMYYY, __images, __brand, __variants, __options, ...rest }) => ({
         ...rest,
         __brand: __brand ? { ...__brand, $updatedAt_DDMMYYY: null } : null,
         __images: __images ? [Object.assign({}, __images["0"])] : null,
         __variants: __variants ? [{ ...__variants["0"], $updatedAt_DDMMYYY: null }] : null,
         __options: __options ? [{ ...__options["0"], $updatedAt_DDMMYYY: null }] : null,
      })
   );
   // * finish fetch ssr for Flash sale

   // *** fetch ssr for San pham ban chay
   const sanPhamBanChayPromise: any = await Product.paginate({
      userIp,
      limit: 6,
      groupIds: "san-pham-ban-chay",
      sort: "desc",
      cookiesToken,
   });
   const dataOfSSRSanPhamBanChay = {
      ...sanPhamBanChayPromise,
      paginate: { ...sanPhamBanChayPromise?.paginate },
   };
   dataOfSSRSanPhamBanChay.paginate.docs = dataOfSSRSanPhamBanChay?.paginate?.docs?.map(
      ({ $updatedAt_DDMMYYY, __brand, __variants, __images, __options, ...rest }) => ({
         ...rest,
         __brand: __brand ? { ...__brand, $updatedAt_DDMMYYY: null } : null,
         __images: __images ? [Object.assign({}, __images["0"])] : null,
         __variants: __variants ? [{ ...__variants["0"], $updatedAt_DDMMYYY: null }] : null,
         __options: __options ? [{ ...__options["0"], $updatedAt_DDMMYYY: null }] : null,
      })
   );
   // * finish fetch ssr for Công trình

   // *** fetch ssr for CommunityOverall
   const resCommunityOverall: any = await HomePage.socialFeeds();
   const dataOfSSRCommunityOverall = JSON.parse(JSON.stringify(resCommunityOverall));
   // * finish fetch ssr for CommunityOverall

   // *** fetch ssr data for brandsResult
   const brandsResult: any = await Brand.paginate({ limit: 200 });
   brandsResult.docs = brandsResult?.docs?.map(({ $updatedAt_DDMMYYY, ...item }) => item);
   if (brandsResult?.docs) {
      brandsResult.docs.forEach((brand: IBrand) => {
         if (brand.logo) {
            delete brand.logo;
         }
      });
   }
   // * finish fetch ssr data for brandsResult

   return {
      props: {
         seo,
         banners: bannerSSRData || [],
         categoriesHinhAnhNoiThatSSRData: categoriesHinhAnhNoiThatSSRData || [],
         dataOfSSRHinhAnhNoiThatBySlug: dataOfSSRHinhAnhNoiThatBySlug || [],
         dataOfSSRHinhAnhNoiThat: dataOfSSRHinhAnhNoiThat || [],
         dataOfSSRCanHoChungCuBySlug: dataOfSSRCanHoChungCuBySlug || [],
         dataOfSSRFlashSale: dataOfSSRFlashSale || [],
         dataOfSSRSanPhamBanChay: dataOfSSRSanPhamBanChay || [],
         dataOfSSRCommunityOverall: dataOfSSRCommunityOverall || [],
         // productsPropose: productsPropose || [],
         brands: brandsResult?.docs || [],
         // partners: partners || [],
         totalDuAnThucTe,
         totalThuVienThietKe,
         totalCongTrinhThucTe,
         totalToAmCuaToi,
      } as {
         seo: NextSeoProps;
         banners: IBanner[];
         categoriesHinhAnhNoiThatSSRData: TListRoom;
         dataOfSSRHinhAnhNoiThatBySlug: any;
         dataOfSSRHinhAnhNoiThat: any;
         dataOfSSRCanHoChungCuBySlug: any;
         dataOfSSRThuVienThietKe: any;
         dataOfSSRCongTrinhThucTe: any;
         dataOfSSRFlashSale: any;
         dataOfSSRSanPhamBanChay: any;
         dataOfSSRCommunityOverall: any;
         dataOfSSRToAmCuaToi: any;
         productsPropose: any;
         brands: Brand[];
         partners: any;
         totalDuAnThucTe: number;
         totalThuVienThietKe: number;
         totalCongTrinhThucTe: number;
         totalToAmCuaToi: number;
      },
   };
};
